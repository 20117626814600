import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useBasket from '@hooks/useBasket'
import { Select } from '@objects/formfields'
import Button from '@objects/button'
import Grid from '@material-ui/core/Grid'
import Container from '@objects/container'
import Headline from '@objects/headline'
import BreadCrumbs from '@objects/breadcrumb'
import Link from '@objects/link'
import Img, { FluidObject } from 'gatsby-image'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    // background: theme.palette.background.light
  },
  headlineWrapper: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    marginBottom: 0,
  },
  gridWrapper: {
    marginBottom: 0,
    paddingBottom: theme.spacing(30),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(50),
    },
  },
  basketItem: {
    borderBottom: `1px ${theme.palette.text.teaser} solid`,
    padding: theme.spacing(8, 0),
  },
  basketItemInfo: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  basketItemName: {
    minHeight: '60px',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 6),
    },
  },
  itemThumb: {
    height: '60px',
    width: '60px',
  },
  itemButtonsWrapper: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  itemAmountSelectWrapper: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  itemAmountSelect: {
    width: '100%',
    minWidth: '100px',
    [theme.breakpoints.up('md')]: {
      minWidth: '100%',
    },
  },
  buttonBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(15),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  buttonBarItem: {
    width: '100%',
    margin: theme.spacing(4, 0),
    textAlign: 'center',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      margin: '0 !important',
    },
  },
  addMoreButton: {},
  infoText: {
    ...theme.typography.smallHelperText,
    color: theme.palette.text.tertiary,
  },
  linkInline: {
    fontWeight: 'bold',
    display: 'inline-block',
    borderBottom: `2px ${theme.palette.primary.main} solid`,
  },
  centered: {
    textAlign: 'center',
  },
}))

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  const { basket: basketItems, removeItem, addItem, setDeleted } = useBasket()
  const intl = useIntl()

  return (
    <section className={classes.root}>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs
          crumbList={[
            {
              label: intl.formatMessage({
                id: 'navigation.breadcrumb.current.label',
              }),
              link: intl.formatMessage({
                id: 'navigation.breadcrumb.current.url',
              }),
            },
          ]}
        />
      </Container>
      <Container className={classes.headlineWrapper} width={'sm'}>
        <Headline level={1}>Warenkorb</Headline>
      </Container>
      <Container
        className={classes.gridWrapper}
        background={'light'}
        width={'sm'}
      >
        <Grid container>
          {basketItems &&
            basketItems.map((basketItem) => {
              return (
                <React.Fragment key={`basket-${basketItem.id}`}>
                  {(basketItem.deleted && (
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.basketItem}
                      justifyContent={'space-around'}
                    >
                      <Link
                        className={clsx(classes.linkInline, classes.centered)}
                        to={'#'}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          setDeleted(basketItem.id, false)
                        }}
                      >
                        <FormattedMessage id={'basket.checkout.undoDelete'} />{' '}
                      </Link>
                    </Grid>
                  )) || (
                    <Grid item xs={12} className={classes.basketItem}>
                      <Grid container>
                        <Grid
                          className={classes.basketItemInfo}
                          item
                          xs={12}
                          md={7}
                        >
                          <Img
                            className={classes.itemThumb}
                            fluid={basketItem.thumb.fluid as FluidObject}
                            alt={''}
                          />

                          <div className={classes.basketItemName}>
                            <span>{basketItem.name}</span>
                          </div>
                        </Grid>

                        <Grid
                          className={classes.itemButtonsWrapper}
                          container
                          item
                          xs={12}
                          md={5}
                        >
                          <Grid
                            item
                            className={classes.itemAmountSelectWrapper}
                          >
                            <Select
                              className={classes.itemAmountSelect}
                              settings={{
                                settingsTheme: 'dark',
                                options: basketItem.amountOpts as ReadonlyArray<
                                  any | any
                                >,
                                defaultValue: {
                                  value: basketItem.amount,
                                  label: basketItem.amount,
                                } as readonly any[] | any | null,
                                onChange: (item) =>
                                  item &&
                                  addItem({
                                    ...basketItem,
                                    amount: parseInt(item.value),
                                  }),
                              }}
                            />
                          </Grid>

                          <Grid item>
                            <Button
                              icon={'Trash'}
                              light
                              onClick={() => {
                                setDeleted(basketItem.id, true)
                              }}
                            >
                              Löschen
                            </Button>
                          </Grid>

                          {basketItem.amount ===
                            basketItem.amountOpts[
                              basketItem.amountOpts.length - 1
                            ].value && (
                            <Grid container item xs={12}>
                              <p className={classes.infoText}>
                                <FormattedMessage
                                  id={'basket.maxItems'}
                                  values={{
                                    // eslint-disable-next-line react/display-name
                                    contactLink: (contact: string) => (
                                      <Link
                                        className={classes.linkInline}
                                        to={'/kontakt/'}
                                        hideIcon
                                      >
                                        {contact}
                                      </Link>
                                    ),
                                  }}
                                />
                              </p>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              )
            })}
          <Grid item className={classes.buttonBar}>
            <Button
              icon={'Add'}
              iconFontSize={20}
              iconPosition={'right'}
              to={'/aktuelles-und-downloads/aktionsmaterial/'}
              className={`${classes.buttonBarItem} ${classes.addMoreButton}`}
              hideLinkIcon
              light
            >
              Weitere hinzufügen
            </Button>
            <Button
              className={classes.buttonBarItem}
              to={'/aktuelles-und-downloads/checkout/'}
              disabled={
                !basketItems.filter((item) => {
                  return !item.deleted
                }).length
              }
            >
              Bestellung abschließen
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
